<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Placements
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
								.form-group
									label Status
									select.form-control(v-model="filters.status")
										option(v-for="o in placementStatusOptions", :value="o.v") {{ o.t }}
								.form-group
									label Offer Status
									select.form-control(v-model="filters.offer_status")
										option(v-for="o in offerStatusOptions", :value="o.v") {{ o.t }}
								select-publisher(v-model="filters.publisher_id")
								select-advertiser(v-model="filters.advertiser_id")
								.form-group.no-label
									button.btn.btn-primary(type="submit") Go

				p.clearfix &nbsp;

				table.table.table-bordered.table-compact
					thead
						tr
							th ID
							th Status
							th Publisher
							th Advertiser
							th Offer
							th Payout
							th Model
							th Click Cap
							th Imp. Cap
							th Conv. Cap
							th Links
							th Actions
					tbody
						tr(v-if="records.length===0")
							td(colspan="11") No matching records were found
						tr(v-for="r in records", :key="r.id")
							td {{ r.id }}
							td {{ r.status }}
								div(v-if="r.offer")
									small.text-muted {{ r.offer.status }}
							td
								entity(v-if="r.publisher", :id="r.publisher.id", :name="r.publisher.name", type="publisher")
							td
								entity(v-if="r.advertiser", :id="r.advertiser.id", :name="r.advertiser.name", type="advertiser")
							td
								entity(v-if="r.offer", :id="r.offer.id", :name="r.offer.name", type="offer")
							td {{ r.payout }} {{ r.currency }}
								div(v-if="r.offer")
									small.text-muted {{ r.offer.payout }} {{ r.offer.currency }}
							td {{ r.payout_model }}
								div(v-if="r.offer")
									small.text-muted {{ r.offer.payout_model }}
							td {{ r.current_click_count }} / {{ r.daily_click_cap }}
								div(v-if="r.offer")
									small.text-muted {{ r.offer.current_click_count }} / {{ r.offer.daily_click_cap }}
							td {{ r.current_impression_count }} / {{ r.daily_impression_cap }}
								div(v-if="r.offer")
									small.text-muted {{ r.offer.current_impression_count }} / {{ r.offer.daily_impression_cap }}
							td {{ r.current_conversion_count }} / {{ r.daily_conversion_cap }}
								div(v-if="r.offer")
									small.text-muted {{ r.offer.current_conversion_count }} / {{ r.offer.daily_conversion_cap }}
							td.quick-links.actions
								a.btn.btn-sm.btn-action.btn-secondary(href="javascript:void(0)", :id="`pop-click_url-${r.id}`", v-b-tooltip.hover, title="Show Click URL")
									i.la.la-link
								b-popover(:target="`pop-click_url-${r.id}`", placement="left", title="Tracking Links", custom-class="tracking-links-popover")
									div
										.form-group
											label Click URL
											textarea.quickview-url.form-control(readonly="readonly") {{ r.pub_click_url }}
										.form-group
											label Impression URL
											textarea.quickview-url.form-control(readonly="readonly") {{ r.pub_impression_url }}
							td.actions
								router-link.btn.btn-action.btn-secondary(:to="{name:'placement-edit', params: {id:r.id}}")
									i.la.la-pen
								button.btn.btn-action.btn-danger(type="button", @click="deleteRecord(r)")
									i.la.la-trash

				paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<style lang="scss">
.tracking-links-popover {
	width: 300px;
	.popover-body {
		padding: 5px;
		.form-group {
			margin-bottom: 5px;
			&:last-child {
				margin-bottom: 0;
			}
			label {
				margin-bottom: 0;
			}
		}
	}
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
export default {
	name: 'PlacementList',
	data(){
		return {
			busy: false,
			// PERMS: null,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filters: {
				keyword: '',
				status: null,
				offer_status: null,
				offer_id: [],
				publisher_id: [],
				advertiser_id: []
			},
			records: [],
			filtersExpanded: false,
			offerStatusOptions: [{
				v: null,
				t: '- All -',
			}, ...Vue.$dwData.general.offerStatusOptions.filter(o => o.v !== 'draft')],
			placementStatusOptions: [{
				v: null,
				t: '- All -',
			}, ...Vue.$dwData.general.placementStatusOptions.filter(o => o.v !== 'draft')],
			payoutModelOptions: Vue.$dwData.general.payoutModelOptions,
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Media' },
			{ title: 'Placements' }
		]);
	},
	methods: {

		async deleteRecord(job){
			if (!confirm('Are you sure?')){
				return;
			}
			try {
				let data = {
					id: job.id
				};
				await this.$api.post('placements/delete', data);
				this.busySave = false;
				this.$notify.success('Placement has been removed');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},

		async loadData(){
			if (this.busy){
				return;
			}
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
				status: this.filters.status,
				platform: this.filters.platform,
				countries: this.filters.countries,
				publisher_id: this.filters.publisher_id.map(r => r.v).join(','),
				advertiser_id: this.filters.advertiser_id.map(r => r.v).join(','),
			};
			try {
				let resp = await this.$api.get('/placements/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		}
	},
	created(){
		this.loadData();
	}

}
</script>
